import { createTheme } from "@material-ui/core/styles"
//import purple from '@material-ui/core/colors/purple'
//import blueGrey from "@material-ui/core/colors/blueGrey"

const theme = createTheme({
  palette: {
    primary: {
      main: "#064dae",
    },
    secondary: {
      main: "#000000",
      contrastText: "#fff",
    },
  },

  typography: {
    fontFamily: ["Arial", "Roboto"].join(","),
  },
  body: {
    fontFamily: '"Arial", Open Sans',
  },
})

export default theme
