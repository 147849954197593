//import React, { useContext, useEffect, useState } from "react"
import React from "react"
import { makeStyles, Drawer } from "@material-ui/core"
import Menu from "./Menu"

const drawerWidth = 240
const estilos = makeStyles(theme => ({
  drawer: {
    //width: drawerWidth,
    //flexShrink: 0,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}))

const Cajon = props => {
  const classes = estilos()
  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
    >
      {/*       <div className={classes.toolbar} style={{marginBottom: 20}}>
        <Link to={"/inicio/"} style={{ textDecoration: 'none' }} > 
          <StaticImage
            src="../images/logo_view.png"
            alt="Logo"
            placeholder="blurred"
            //layout="constrained"
          //  width={"50%"}
          // height={200}
          />
        </Link>
      </div>
     
      <Divider />
      */}
      <Menu />
    </Drawer>
  )
}

export default Cajon
