import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import { ThemeProvider, makeStyles } from "@material-ui/core"
import theme from "../themeConfig"
import Cajon from "./Cajon"
import Chat from "./Chat"

const estilos = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2), //uso nombre que quiera
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing(3),
  },
}))

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
      configuracion: strapiConfiguracion {
        chat
      }
    }
  `)

  const classes = estilos()
  const [abrir, setAbrir] = React.useState(false)
  const [abrirChat, setAbrirChat] = React.useState(false)

  const accionAbrir = () => {
    setAbrir(!abrir)
  }
  const accionAbrirChat = () => {
    setAbrirChat(!abrirChat)
  }

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          accionAbrir={accionAbrir}
          accionAbrirChat={accionAbrirChat}
        />

        <Cajon
          variant="temporary"
          open={abrir}
          onClose={accionAbrir}
          menuLinks={data.site.siteMetadata?.menuLinks}
        />
        {data.configuracion.chat ? (
          <Chat
            variant="temporary"
            open={abrirChat}
            onClose={accionAbrirChat}
          />
        ) : (
          ""
        )}
        <div className={classes.content}>
          <div className={classes.toolbar}>
            <main>{children}</main>
          </div>
        </div>
      </ThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout