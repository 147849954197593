import React, { useContext } from "react"
import { makeStyles, Drawer } from "@material-ui/core"
import { AuthContext } from "../context/auth"

const drawerWidth = 540
const estilos = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: "40%",
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
      width: "40%",
    // width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}))

const Chat = props => {
  const classes = estilos()
  const { user } = useContext(AuthContext)

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      variant={props.variant}
      ModalProps={{
        keepMounted: true,
      }}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
    >
      {user != null ? (
        <iframe
          style={{
            height: "100%",
            width: "100%",
            overflowX: "hidden"
          }}
          src={`https://chat.002.virtualeventweb.com/chat/${user.uid}`}
          title="chat"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          permissions="geolocation notifications midi"
          mozallowfullscreen="true"
          allowFullScreen
        />
      ) : (
        ""
      )}
    </Drawer>
  )
}

export default Chat

