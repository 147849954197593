import React, { useContext, useState } from "react"
import { AuthContext } from "../context/auth"
import {
  Modal,
  Button,
  CssBaseline,
  TextField,
  Typography,
  makeStyles,
  ListItem,
  ListItemText,
  // Container,
} from "@material-ui/core"
import { injectIntl } from "gatsby-plugin-intl"
import QRCode from "./QRCode"
import { graphql, useStaticQuery, navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  paper: {
    //  position: "absolute",
    // width: "80%",
    //  left: "10%",
    // top: "5%",
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    //   padding: theme.spacing(2, 4, 3),
  },
}))

const MiQR = ({ intl }) => {
  const { configuracion } = useStaticQuery(graphql`
    query {
      configuracion: strapiConfiguracion {
        menu {
          qr {
            label_qr
            color_fondo
            color_qr
            size
          }
        }
      }
    }
  `)
  const { user } = useContext(AuthContext)
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const currentLocale = intl.locale

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const body = (
    <div className={classes.paper}>
      <QRCode
        value={user.email}
        foreground={configuracion.menu.qr.color_qr}
        background={configuracion.menu.qr.color_fondo}
        size={configuracion.menu.qr.size}
      />
    </div>
  )

  return (
    <>
    <div onClick={handleOpen} style={{width: "100%"}}>
       {/* <Button type="button" onClick={handleOpen}> */}
        {/* <Button fullWidth variant="contained" onClick={handleOpen}> */}
        <ListItemText >
        {configuracion.menu.qr.label_qr}
        </ListItemText>
      {/* </Button> */}
    </div>

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  )
}

export default injectIntl(MiQR)
