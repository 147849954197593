import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../context/auth"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Fade,
  Backdrop,
  Modal,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import publicIp from "public-ip"
import firebase from "gatsby-plugin-firebase"
import ChatIcon from "@material-ui/icons/Chat"
import { graphql, useStaticQuery, navigate } from "gatsby"
import axios from "axios"

//const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  /*     alturaDeHeader: {
      altoHeader: theme.mixins.Toolbar
    } , */
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2), //uso nombre que quiera
  },
  title: {
    flexGrow: 1,
  },
  AppBar: {
    /*   [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
   } */
    width: 100,
    left: 0,
    backgroundColor: "transparent",
    boxShadow: "none",
    margin: 0,
    padding: 0,
  },
  AppBarChat: {
    width: 100,
    right: 0,
    backgroundColor: "transparent",
    boxShadow: "none",
    margin: 0,
    padding: 0,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Header = props => {
  const { configuracion } = useStaticQuery(graphql`
    query {
      configuracion: strapiConfiguracion {
        notificacionActiva
        gpsUsuariosActivo
        gps_link_planilla
        chat
      }
    }
  `)

  const classes = useStyles()
  const { user } = useContext(AuthContext)

  const getClientIp = async () =>
    await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    })
  const [ip, setIp] = useState("")
  useEffect(() => {
    getClientIp().then(result => setIp(result))
  }, [])

  useEffect(() => {
    if (user && ip !== "") {
      //console.log(user.uid)
      const useruid = user.uid
      ipUsuario({ useruid, ip })
    }
  }, [ip])

  const handleLogOut = async () => {
    await firebase.auth().signOut()
    navigate("/")
  }

  const ipUsuario = ({ useruid, ip }) => {
    firebase
      .firestore()
      .collection("usuarios")
      .doc(useruid)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          localStorage.setItem("uid", doc.id)
          localStorage.setItem("nombre", doc.data().nombre)
          localStorage.setItem("apellido", doc.data().apellido)
          localStorage.setItem("espago", doc.data().espago)
          localStorage.setItem("email", doc.data().email)
          localStorage.setItem("telefono", doc.data().telefono)
          localStorage.setItem("whatsapp", doc.data().whatsapp)
          localStorage.setItem("sitioweb", doc.data().sitioweb)
          localStorage.setItem("ramo", doc.data().ramo)
          localStorage.setItem("pais", doc.data().pais)
          localStorage.setItem("ciudad", doc.data().ciudad)
          localStorage.setItem("empresa", doc.data().empresa)
          localStorage.setItem("cargo", doc.data().cargo)
          localStorage.setItem("interesado", doc.data().interesado)
          localStorage.setItem("categoria", doc.data().categoria)
          // localStorage.setItem("instagram", doc.data().instagram)
          localStorage.setItem("chat", doc.data().chat)
          //localStorage.setItem("linkedin", doc.data().linkedin)
          localStorage.setItem("logo", doc.data().logo)
          localStorage.setItem("ip", ip)
          //localStorage.setItem("registradoRonda", doc.data().registradoRonda)
          //       console.log("horas", doc.data().horas)

          if (doc.data().ip !== ip) {
            handleLogOut()
          }
        } else {
          navigate("/mis_datos")
        }
      })
  }

  const [open, setOpen] = useState(false)
  const [notificacion, setNotificacion] = useState({})

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    localStorage.setItem("contadorNotificacion", notificacion.contador)
  }

  useEffect(() => {
    if (configuracion.notificacionActiva) {
      firebase
        .firestore()
        .collection("notificaciones")
        .doc("notificacion")
        .onSnapshot(function (doc) {
          if (doc.exists) {
            if (doc.data().mostrar) {
              setNotificacion(doc.data())
              if (
                localStorage.getItem("contadorNotificacion") ==
                doc.data().contador
              ) {
                // con == comparo valor
              } else {
                handleOpen()
              }
            }
          }
        })
      // console.log("esta on")
    } else {
      //  console.log("esta off")
      return
    }
  }, [])

  const irA = () => {
    handleClose()
    if (notificacion.tipo_link === "interno") {
      navigate(notificacion.link)
    } else {
      window.open(notificacion.link, "_blank")
    }
  }

  /** GPS usuarios */
  useEffect(() => {
    if (configuracion.gpsUsuariosActivo) {
      if (user != null && window.location.pathname != "/") {
        let today = new Date()
        let date =
          today.getDate() +
          "/" +
          (today.getMonth() + 1) +
          "/" +
          today.getFullYear()
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()

        let gpsSubir = {
          url: window.location.pathname,
          email: user.email,
          nombre: localStorage.getItem("nombre"),
          apellido: localStorage.getItem("apellido"),
          hora: time,
          fecha: date,
          gmt: Date.now().toString(),
          ip: localStorage.getItem("ip"),
        }
        axios.post(configuracion.gps_link_planilla, gpsSubir)
        /*         axios.post("https://servidor.virtualeventweb.com:1340/plataforma-gps", {
          url: window.location.pathname,
          email: user.email,
          fecha: Date.now().toString(),
          ip: localStorage.getItem("ip"),
          uid: user.uid,
          nombre: localStorage.getItem("nombre"),
          apellido: localStorage.getItem("apellido"),
        }) */
      }
    }
  }, [])
  /** GPS usuarios */

  /** NOTIFICACIONES */
  if (configuracion.notificacionActiva) {
    return (
      <header>
        <div className={classes.root}>
          {user ? (
            <>
              <AppBar position="fixed" className={classes.AppBar}>
                <Toolbar>
                  <IconButton
                    aria-label="menu"
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    onClick={() => props.accionAbrir()}
                    style={{
                      color: "#064dae",
                      background: "#ffffff",
                      padding: 14,
                      borderRadius: 60,
                    }}
                  >
                    <MenuIcon />
                    {/* Menu */}
                  </IconButton>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    //  disableBackdropClick={false}
                    open={open}
                    // onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={classes.paper}>
                        <Button type="button" onClick={handleClose}>
                          X
                        </Button>
                        <h2 id="transition-modal-title">
                          {notificacion.titulo}
                        </h2>
                        <p id="transition-modal-description">
                          {notificacion.texto}
                        </p>
                        <Button
                          // style={{ background: `${color_fondo_marco}`, padding: 15 }}
                          type="button"
                          onClick={irA}
                        >
                          {notificacion.texto_link}
                        </Button>
                      </div>
                    </Fade>
                  </Modal>
                </Toolbar>
              </AppBar>
              {configuracion.chat ? (
                <AppBar position="fixed" className={classes.AppBarChat}>
                  <IconButton
                    aria-label="chat"
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    onClick={() => props.accionAbrirChat()}
                    style={{
                      color: "#064dae",
                      background: "#ffffff",
                      padding: 14,
                      borderRadius: 60,
                      position: "absolute",
                      top: 3,
                      right: 0,
                    }}
                  >
                    <ChatIcon />
                  </IconButton>
                </AppBar>
              ) : (
                ""
              )}
            </>
          ) : (
            <div></div>
          )}
        </div>
      </header>
    )
  } else {
    return (
      <header>
        <div className={classes.root}>
          {user ? (
            <>
              <AppBar position="fixed" className={classes.AppBar}>
                <Toolbar>
                  <IconButton
                    aria-label="menu"
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    onClick={() => props.accionAbrir()}
                    style={{
                      color: "#064dae",
                      background: "#ffffff",
                      padding: 14,
                      borderRadius: 60,
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              {configuracion.chat ? (
                <AppBar position="fixed" className={classes.AppBarChat}>
                  <IconButton
                    aria-label="chat"
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    onClick={() => props.accionAbrirChat()}
                    style={{
                      color: "#064dae",
                      background: "#ffffff",
                      padding: 14,
                      borderRadius: 60,
                      position: "absolute",
                      top: 3,
                      right: 0,
                    }}
                  >
                    <ChatIcon />
                  </IconButton>
                </AppBar>
              ) : (
                ""
              )}
            </>
          ) : (
            <div></div>
          )}
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
