import PropTypes from "prop-types"
import React, { useState, useContext } from "react"
import { AuthContext } from "../context/auth"
import { graphql, useStaticQuery, navigate } from "gatsby"
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
  Collapse,
} from "@material-ui/core"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import Avatar from "@material-ui/core/Avatar"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import firebase from "gatsby-plugin-firebase"
import { GatsbyImage } from "gatsby-plugin-image"
import MiQR from "../components/MiQR"

import {
  injectIntl,
  FormattedMessage,
  changeLocale,
  Link,
} from "gatsby-plugin-intl"

const useStyles = makeStyles(theme => ({
  /*     alturaDeHeader: {
      altoHeader: theme.mixins.Toolbar
    } , */
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2), //uso nombre que quiera
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
  },
  boton: {
    "& span, & svg": {
      fontSize: "0.8em",
    },
  },
  nested: {
    //  paddingLeft: theme.spacing(4),
    "& span, & svg": {
      fontSize: "0.8em",
    },
  },
}))

const Menu = ({ intl }) => {
  const { user } = useContext(AuthContext)

  const { iconos, configuracion } = useStaticQuery(graphql`
    query {
      iconos: allFile(
        filter: { relativeDirectory: { eq: "idiomas" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            publicURL
          }
        }
      }
      configuracion: strapiConfiguracion {
        usuarios_habilitados {
          email
          administracion
          backend
          id
        }
        administracion_mostrar
        administracion_texto {
          es
          en
          pt
        }
        backend_mostrar
        backend_texto {
          es
          en
          pt
        }
        menu {
          qr {
            label_qr
            mostrar
          }
          color_letra
          email_soporte
          mostrar_idioma
          mis_datos {
            es
            en
            pt
          }
          mis_chats {
            es
            en
            pt
          }
          botones {
            mostrar
            id
            link
            es
            en
            pt
          }
        }
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)

  const imagenesIconosIdiomas = iconos.edges
  const classes = useStyles()
  const currentLocale = intl.locale
  const [openCollapse, setOpenCollapse] = useState(false)
  const [openCollapseIdioma, setOpenCollapseIdioma] = useState(false)
  const handleLogOut = async () => {
    await firebase.auth().signOut()
    navigate("/")
  }

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse)
  }
  function handleOpenSettingsIdioma() {
    setOpenCollapseIdioma(!openCollapseIdioma)
  }
  /*   const datosUsuario =
    localStorage.getItem("nombre") + " " + localStorage.getItem("apellido")
 */
  const usuarioHabilitadoBackend = usuario => {
    let habilitado = false
    configuracion.usuarios_habilitados.forEach(function (revisar) {
      if (revisar.email === usuario && revisar.backend) {
        habilitado = true
      }
    })
    return habilitado
  }
  const usuarioHabilitadoAdministracion = usuario => {
    let habilitado = false
    configuracion.usuarios_habilitados.forEach(function (revisar) {
      if (revisar.email === usuario && revisar.administracion) {
        habilitado = true
      }
    })
    return habilitado
  }
  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <Link to={"/inicio"} style={{ textDecoration: "none" }}>
          {configuracion.logo !== null &&
          configuracion.logo.localFile !== null ? (
            <GatsbyImage
              image={
                configuracion.logo.localFile.childImageSharp.gatsbyImageData
              }
              alt="logo"
            />
          ) : (
            ""
          )}
        </Link>
      </div>

      <List component="nav">
        <ListItem
          button
          className={classes.boton}
          onClick={handleOpenSettings}
          style={{ color: `${configuracion.menu.color_letra}` }}
        >
          <ListItemText>{user && user.displayName}</ListItemText>
          {openCollapse ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link className={classes.link} to="/mis_datos">
              <ListItem
                button
                key="datos"
                className={classes.nested}
                style={{ color: `${configuracion.menu.color_letra}` }}
              >
                <ListItemText>
                  {currentLocale === "en"
                    ? configuracion.menu.mis_datos.en
                    : currentLocale === "pt"
                    ? configuracion.menu.mis_datos.pt
                    : configuracion.menu.mis_datos.es}
                </ListItemText>
              </ListItem>
            </Link>
            {/*           <Link className={classes.link} to="/mis_chats">
              <ListItem
                button
                key="chats"
                className={classes.nested}
                style={{ color: `${configuracion.menu.color_letra}` }}
              >
                <ListItemText>
                  {currentLocale === "en"
                    ? configuracion.menu.mis_chats.en
                    : currentLocale === "pt"
                    ? configuracion.menu.mis_chats.pt
                    : configuracion.menu.mis_chats.es}
                </ListItemText>
              </ListItem>
            </Link> */}
            {configuracion.menu.qr.mostrar ? (
              <ListItem
                button
                key="datos"
                className={classes.nested}
                style={{ color: `${configuracion.menu.color_letra}` }}
              >
                <MiQR />
              </ListItem>
            ) : (
              ""
            )}
            {configuracion.backend_mostrar ||
            usuarioHabilitadoBackend(user.email) ? (
              <Link className={classes.link} to="/back">
                <ListItem
                  button
                  key="Back"
                  className={classes.nested}
                  style={{ color: `${configuracion.menu.color_letra}` }}
                >
                  <ListItemText>
                    {currentLocale === "en"
                      ? configuracion.backend_texto.en
                      : currentLocale === "pt"
                      ? configuracion.backend_texto.pt
                      : configuracion.backend_texto.es}
                  </ListItemText>
                </ListItem>
              </Link>
            ) : (
              ""
            )}
            {configuracion.administracion_mostrar ||
            usuarioHabilitadoAdministracion(user.email) ? (
              <Link className={classes.link} to="/administracion">
                <ListItem
                  button
                  key="Back"
                  className={classes.nested}
                  style={{ color: `${configuracion.menu.color_letra}` }}
                >
                  <ListItemText>
                    {currentLocale === "en"
                      ? configuracion.administracion_texto.en
                      : currentLocale === "pt"
                      ? configuracion.administracion_texto.pt
                      : configuracion.administracion_texto.es}
                  </ListItemText>
                </ListItem>
              </Link>
            ) : (
              ""
            )}
            <ListItem
              button
              onClick={handleLogOut}
              className={classes.boton}
              style={{ color: `${configuracion.menu.color_letra}` }}
            >
              <ListItemText>
                <FormattedMessage id="cerrar_sesion" />
              </ListItemText>
            </ListItem>
          </List>
          <Divider />
        </Collapse>
        {configuracion.menu.botones.map(boton => (
          <div key={boton.id}>
            {boton.mostrar ? (
              <Link key={boton.id} className={classes.link} to={boton.link}>
                <ListItem
                  button
                  key={boton.id}
                  className={classes.boton}
                  style={{ color: `${configuracion.menu.color_letra}` }}
                >
                  <ListItemText>
                    {currentLocale === "en"
                      ? boton.en
                      : currentLocale === "pt"
                      ? boton.pt
                      : boton.es}
                  </ListItemText>
                </ListItem>
              </Link>
            ) : null}
          </div>
        ))}

        {configuracion.menu.mostrar_idioma ? (
          <div>
            <ListItem
              button
              className={classes.boton}
              onClick={handleOpenSettingsIdioma}
              style={{ color: `${configuracion.menu.color_letra}` }}
            >
              <ListItemText>
                <FormattedMessage id="idioma" />
              </ListItemText>
              {openCollapseIdioma ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openCollapseIdioma} timeout="auto" unmountOnExit>
              {currentLocale !== "en" ? (
                <ListItem
                  button
                  onClick={() => changeLocale("en")}
                  className={classes.boton}
                  style={{ color: `${configuracion.menu.color_letra}` }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={imagenesIconosIdiomas[1].node.publicURL}
                      alt="English"
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    <FormattedMessage id="en" />
                  </ListItemText>
                </ListItem>
              ) : (
                ""
              )}
              {currentLocale !== "es" ? (
                <ListItem
                  button
                  onClick={() => changeLocale("es")}
                  className={classes.boton}
                  style={{ color: `${configuracion.menu.color_letra}` }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={imagenesIconosIdiomas[0].node.publicURL}
                      alt="Español"
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    <FormattedMessage id="es" />
                  </ListItemText>
                </ListItem>
              ) : (
                ""
              )}
              {currentLocale !== "pt" ? (
                <ListItem
                  button
                  onClick={() => changeLocale("pt")}
                  className={classes.boton}
                  style={{ color: `${configuracion.menu.color_letra}` }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={imagenesIconosIdiomas[2].node.publicURL}
                      alt="Portuguese"
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    <FormattedMessage id="pt" />
                  </ListItemText>
                </ListItem>
              ) : (
                ""
              )}
            </Collapse>
          </div>
        ) : (
          ""
        )}
        <Divider />

        <a
          className={classes.link}
          href={`mailto:${configuracion.menu.email_soporte}`}
        >
          <ListItem
            button
            key="soporte"
            className={classes.boton}
            style={{ color: `${configuracion.menu.color_letra}` }}
          >
            <ListItemText>
              <FormattedMessage id="soporte" />
            </ListItemText>
          </ListItem>
        </a>
        <Link className={classes.link} to="/tos/">
          <ListItem
            button
            key="Tos"
            className={classes.boton}
            style={{ color: `${configuracion.menu.color_letra}` }}
          >
            <ListItemText>
              <FormattedMessage id="tos" />
            </ListItemText>
          </ListItem>
        </Link>
      </List>
    </div>
  )
}

Menu.propTypes = {
  siteTitle: PropTypes.string,
}

Menu.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Menu)
